.menu {
  background-color: $dark-bg;
  height: fit-content;

  img {
    animation: pulse 3s infinite linear;
  }

  &-name {
    color: $white;
    font-size: calc(10px + 2vmin);
    font-family: $rubik;
    font-weight: 700;
    margin: 20px 0 35px 0;
    display: flex;
    flex-direction: column;
    line-height: 35px;
    text-align: center;
  }

  a {
    color: $white;
    //font-size: calc(5px + 2vmin);
    font-family: $urbanist;
    font-weight: 400;
    position: relative;
    cursor: pointer;
    white-space:nowrap;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background: $light-hover-color;
      visibility: hidden;
      border-radius: 5px;
      transform: scaleX(0);
      transition: 0.25s linear;
    }

    &:hover::before,
    &:focus::before,
    &.selected {
      visibility: visible;
      transform: scaleX(1);
    }

    &:hover,
    &.selected {
      color: $light-hover-color;
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0px 0px 18px 3px rgba(190, 245, 45, 0.75);
    }
    50% {
      box-shadow: 0px 0px 10px 1px rgba(190, 245, 45, 0.5);
    }
    100% {
      box-shadow: 0px 0px 18px 3px rgba(190, 245, 45, 0.75);
    }
  }

}