@tailwind base;
@tailwind components;
@tailwind utilities;

//include media
@import './node_modules/include-media/dist/_include-media.scss';

//fonts
@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&family=Ubuntu:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&family=Urbanist:wght@100;200;300;400;500;600;700&family=Ysabeau+SC:wght@100;200;300;400;500;600;700&display=swap');
$rubik: 'Rubik', sans-serif;
$lobster: 'Lobster', cursive;
$nunito: 'Nunito', sans-serif;
$ubuntu: 'Ubuntu', sans-serif;
$ysabeau: 'Ysabeau SC', sans-serif;
$indie: 'Indie Flower', cursive;
$urbanist: 'Urbanist', sans-serif;

//colors
$light-color: #09445f;
$dark-color: rgba(5, 38, 54, 0.7);
$dark-bg: #001C30; //alternative: #0B2447
$light-hover-color: #bef52d;
$white: #ffffff;
$bg-header: #e1dede;

@import 'Menu';

html { 
  scroll-behavior: smooth;
  font-variant-ligatures: no-common-ligatures;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root { 
  background-color: $dark-bg;
  // background: rgb(190,245,45);
  // background: -moz-linear-gradient(319deg, rgba(190,245,45,1) 0%, rgba(9,68,95,1) 13%, rgba(3,35,50,1) 50%, rgba(9,68,95,1) 70%, rgba(190,245,45,1) 100%);
  // background: -webkit-linear-gradient(319deg, rgba(190,245,45,1) 0%, rgba(9,68,95,1) 13%, rgba(3,35,50,1) 50%, rgba(9,68,95,1) 70%, rgba(190,245,45,1) 100%);
  // background: linear-gradient(319deg, rgba(190,245,45,1) 0%, rgba(9,68,95,1) 13%, rgba(3,35,50,1) 50%, rgba(9,68,95,1) 70%, rgba(190,245,45,1) 100%);
  // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#bef52d",endColorstr="#bef52d",GradientType=1);
  scrollbar-width: thin;
  scrollbar-color: #0A4C95 #C2D2E4;
  overflow-x: hidden;
}

h1 {
  font-family: $nunito;
}

.about,
.skills,
.studies {
  font-family: $rubik;
}

.experience,
.studies {
  a:hover {
    div {
      color: $light-hover-color;
    }
  }
}

.about {
  svg {
    color: $light-hover-color;
  }
}

//custom scrollbar
::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  background: $light-color;
}

::-webkit-scrollbar-thumb {
  background: $light-hover-color;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px $dark-color;
}

::-webkit-scrollbar-corner {
  background: $light-color;
}

::selection {
  color: $dark-color;
  background-color: $light-hover-color;
}

//cookies
#cookiescript_badge,
#cookiescript_injected {
  bottom: 105px !important;
  border: 2px solid $light-hover-color;
}